<template>
  <div id="recordDialog">
    <el-dialog
        title="记录详情"
        :visible.sync="show"
        width="720px"
        @open="getRecord"
    >
      <el-table
          :data="table.tableData"
          style="width: 100%"
          v-loading="table.loading"
      >
        <el-table-column label="车牌号1" align="center">
          <template #default="scope">
            <span :class="{differentPlateNum:!scope.row.isSamePlateNum}">{{scope.row.plateNum}}</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌号2" align="center">
          <template #default="scope">
            <span :class="{differentPlateNum:!scope.row.isSamePlateNum}">{{scope.row.plateExtNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="channelName" label="出入口名称" align="center">
        </el-table-column>
        <el-table-column prop="channelType" label="设备类型" align="center">
        </el-table-column>
        <el-table-column prop="liftType" label="抬杆类型" align="center">
        </el-table-column>
        <el-table-column prop="recordStatus" label="车辆停车状态" align="center">
        </el-table-column>
        <el-table-column prop="channelType" label="设备类型" align="center">
        </el-table-column>
        <el-table-column prop="createAt" label="时间" align="center">
        </el-table-column>
        <el-table-column label="抓拍照片" align="center">
          <template slot-scope="scope">
            <el-image
                style="width: 70px; height: 70px"
                :src="scope.row.imageUrl"
                fit="contain" :preview-src-list="[scope.row.imageUrl]"></el-image>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return {
      show: false,
      parkingRecordId: "",
      table: {
        tableData: [],
        loading: false,
      },
    }
  },
  methods:{
    async getRecord(){
      try {
        this.table.loading = true;
        let res = await this.$http.get(`/parking/record/list/${this.parkingRecordId}/device`);
        if (res.code === 0) {
          for (let item of res.data) {
            item.createAt = this.$moment(item.createAt).format(
                "yyyy-MM-DD HH:mm:ss"
            );
            if(!item.plateNum || !item.plateExtNum){
              item.isSamePlateNum = true
            }else{
              item.isSamePlateNum = item.plateNum===item.plateExtNum
            }
          }
          this.table.tableData = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    }
  }
}
</script>


<style lang="scss">
#recordDialog{
  .el-dialog{
    max-height: calc(100vh - 30vh);
    overflow-y: auto;
  }
}
</style>
